import axios from 'axios'

const baseApi = axios.create({
/// baseURL: "https://yagolos.ru/api"
  baseURL: 'http://127.0.0.1:8000/api/v1'
})

const Api = function () {
  const token = localStorage.getItem('token')
  if (token) {
    baseApi.defaults.headers.common.Authorization = `Bearer ${token}`
  }
  return baseApi
}
export default Api
