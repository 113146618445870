<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-gray-600 text-sm font-bold">
                Вход
              </h6>
            </div>
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form @submit.prevent="submitHandler">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  v-model.trim="email"
                  :class="{ 'bg-red-400': ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email) }"
                  type="email"
                  id="email"
                  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                />
                <small class="text-red-500" v-if="$v.email.$dirty && !$v.email.required">E-mail не может быть пустым</small>
                <small class="text-red-500" v-else-if="$v.email.$dirty && !$v.email.email">Введите корректный E-mail</small>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Пароль
                </label>
                <input
                  v-model.trim="password"
                  :class="{ 'bg-red-400': $v.password.$dirty && !$v.password.required }"
                  id="password"
                  type="password"
                  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="Пароль"
                />
                <small class="text-red-500" v-if="$v.password.$dirty && !$v.password.required">Пароль не может быть пустым</small>
              </div>
              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold text-gray-700">
                    Запомнить меня
                  </span>
                </label>
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-blue-400 text-white active:bg-blue-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Войти
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { email, required } from 'vuelidate/lib/validators'
import auth from '@/auth'
export default {
  name: 'login',
  validations: {
    email: { email, required },
    password: { required }
  },
  data: () => ({
    email: '',
    password: ''
  }),
  methods: {
    submitHandler () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const formData = {
        email: this.email,
        password: this.password
      }
      // console.log(formData)
      // this.$router.push('/user')
      auth.LogIn(formData, '/user')
    }
  }
}
</script>
