import baseApi from '@/baseApi'

const LOGIN_URL = '/login'
const REG_URL = '/registrtion'
const LOGOUT_URL = '/logout'

export default ({
  userDefault: {
    id: '',
    name: '',
    email: '',
    role: '',
    token: ''
  },
  LogIn (cred, redirect) {
    baseApi().post(LOGIN_URL, cred).then((response) => {
      if (!response.data.error) {
        // store.dispatch('SET_AUTH_USER', response.data.user.user)
        // store.dispatch('SET_TOKEN', response.data.user.token)
        // store.dispatch('SET_AUTH', true)
        localStorage.setItem('token', response.data.user.token.value)
        this.$router.push(redirect)
      }
      // console.log(response.data)
    })
  },
  Register (cred, redirect) {
    baseApi().post(REG_URL).then((response) => {

    })
  },
  Logout () {
    baseApi().post(LOGOUT_URL).then((response) => {

    })
  }
})
